<template>
  <div class="statements">
    <div class="statements__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="statements-loading" v-if="loading" v-loading="loading"></div>
    <template v-if="thereAreStatements">
      <div v-if="statementFileExcel" class="document-container document-container--excel">
        <div class="excel">
          <i class="fa-regular fa-file-excel excel__icon"></i>
          <div class="excel-body">
            <span>Name:</span>
            <span class="excel__text">account-statements/2856/statement.xlsx</span>
            <div class="button-container">
              <button
                @click="downloadExcelStatement(statementFileExcel)"
                class="excel-button"
              >
                Download file
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="document-container" v-if="statementFilePdf">
        <PdfViewer
          :currentType="'statements'"
          :invoiceDocument="attachmentsDocuments"
          :previewMode="previewMode"
          :selectedDocument="statementFilePdf"
          :tokenInfo="tokenInfo"
          :isStatement="true"
        />
      </div>
    </template>
    <div v-if="!thereAreStatements && !loading" class="not-found">
      <i class="ion ion-file-tray-outline not-found__icon"></i>
      <p>No documents were found.</p>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../../../components/PdfViewer.vue";

export default {
  name: "Statements",
  components: {
    PdfViewer,
  },
  data() {
    return {
      attachmentsDocuments: [],
      loading: false,
      tokenInfo: null,
      previewMode: false,
      statementFileExcel: null,
      statementFilePdf: null,
      thereAreStatements: false,
    };
  },
  async created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.loading = true;
    this.statementFileExcel = await this.$store.dispatch("account/getStatement");
    if (!this.statementFileExcel) {
      await this.getPdfStatement();
    }
    this.thereAreStatements = this.statementFileExcel || this.statementFilePdf;
    this.loading = false;
  },
  methods: {
    async getPdfStatement() {
      this.attachmentsDocuments = await this.$store.dispatch(
        "load/docsGenerate/getAttachmentsDocs"
      );
      if (this.attachmentsDocuments.length) {
        this.statementFilePdf = this.attachmentsDocuments[0].file;
      }
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    downloadExcelStatement(file) {
      const blobUrl = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = file.name;
      link.click();
      window.URL.revokeObjectURL(blobUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.statements {
  @include splitted-view-styles;
  padding-top: 2%;
  padding-bottom: 20px;
  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}

.not-found {
  padding-top: 200px;
  &__icon {
    font-size: 50px;
  }
}

.statements-loading {
  padding-top: 500px;
}

.document-container {
  height: 100%;
  width: 80%;
  margin: auto;
  border-radius: 15px;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
  }
  &--excel {
    width: 100%;
    margin-top: 30px;
  }
}

.button-download {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: $color-border-container;
  padding-right: 10px;
  font-weight: 400;
  background: $color-white;
  color: $color-border-container;
  border: none;
  span {
    text-decoration: underline;
    margin-left: 5px;
  }
}

::v-deep {
  .insurance-not-found {
    i {
      font-size: 50px;
    }
  }
  .el-table__empty-text {
    line-height: 30px;
  }
}

.button-container {
  display: flex;
  justify-content: end;
}

.excel {
  display: inline-flex;
  border-radius: 13px;
  padding: 10px;
  -webkit-box-shadow: 2px 2px 5px 0px darken($color-gray-light, 20%);
  -moz-box-shadow: 2px 2px 5px 0px darken($color-gray-light, 20%);
  box-shadow: 2px 2px 5px 0px darken($color-gray-light, 20%);
  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    span {
      color: grey;
      &:nth-child(1) {
        font-size: 14px;
      }
    }
  }
  &-button {
    border-radius: 15px;
    border: none;
    background-color: $color-primary-trigger-button;
    color: white;
    height: 35px;
    margin-top: 7px;
    width: 100%;
    font-weight: bold;
    font-size: 14.5px;
    font-weight: 600;
    padding: 5px 0px;
    width: 170px;
    border-radius: 15px;
    font-weight: 400;
  }
  &__icon {
    color: #178047;
    margin: 10px;
    font-size: 50px;
  }
  &__text {
    font-size: 13px;
  }
}
</style>
